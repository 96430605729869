.wrapper {
    padding-bottom: 2em;
    h3 {
        text-align: left;
        color: white;
    }
    tbody {        
        width: 10%;
    }
    th{
        width: 12em;
    }
    tr{
        height: 4em;
        vertical-align: middle;
    }
    td{
        font-weight: normal;
    }
}


