.ScoreList {}

.ScoreGridColumn{
    /* border:"5em solid white" */
    font-size: large;
}   

.HeaderWrapper{
    margin-bottom: 1em;
}
.HeaderButtons > button{
    margin-right: 5px;
    float: right;

}
