/* .container {
    display: flex;
    justify-content: space-around;
    align-items: center; 
    height: 100vh; 
  } */
  
  .item {
    flex: 1;
    /* border: 1px solid black;  */
    /* padding: 20px; 
    margin: 10px;  */
    height: 100vh;
    vertical-align: middle;
    justify-content: center;
    align-self: center;
  }
  