.circle
    {
    width:60px;
    height:60px;
    border-radius:30px;
    font-size:50px;
    color:black;
    line-height:50px;
    text-align:center;
    background:#fff
    }