.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }