 .SchoolRegistrationForm {
    padding: 1em;
    text-align: left;
    border: 1px solid black;
    display: flex;
    flex-direction: column;

    button {
        background: linear-gradient(23deg, rgba(64, 8, 183, 0.20) 40.27%, rgba(208, 143, 44, 0.20) 410.01%), #43099E;
    }
}

.SchoolList {
    padding: 1em;
    width: 50%;
    text-align: -webkit-center;
    button {
        background: linear-gradient(23deg, rgba(64, 8, 183, 0.20) 40.27%, rgba(208, 143, 44, 0.20) 410.01%), #43099E;
    }
    table{
        border:1px solid black
    }
}

.Title {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 1.2em;
    border-bottom: 1px solid black;
}